import { CanActivateFn } from "@angular/router";
import { inject } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { EAccessType } from "../enums/access-types.enum";
import { IAccessTokenPayload } from "../interfaces/auth.interfaces";

type availableAccessTypes =
  | EAccessType.FAT
  | EAccessType.TFA
  | EAccessType.VerifyResetPassword
  | EAccessType.VerifyCompanyRegistration
  | EAccessType.ConfirmResetPassword
  | EAccessType.CompleteRegistration
  | EAccessType.VerifyRegistration
  | EAccessType.CompleteCompanyRegistration;

const accessDefaultRoutesMap: Record<availableAccessTypes, string> = {
  [EAccessType.FAT]: "/",
  [EAccessType.TFA]: "/auth/verify-2fa",
  [EAccessType.VerifyResetPassword]: "/auth/verify-forget-password",
  [EAccessType.VerifyRegistration]: "/employee-auth/verify-register-credentials",
  [EAccessType.VerifyCompanyRegistration]: "/auth/verify-register-credentials",
  [EAccessType.ConfirmResetPassword]: "/auth/new-password",
  [EAccessType.CompleteRegistration]: "/employee-auth/on-boarding",
  [EAccessType.CompleteCompanyRegistration]: "/auth/on-boarding",
};

export const accessTokenGuard: CanActivateFn = (route, state) => {
  const jwtHelper = inject(JwtHelperService);
  const authService = inject(AuthService);
  const router = inject(Router);

  let allowedAccessToken = route.data["allowedAccessToken"];
  let allowedTypes = route.data["allowedTypes"];
  if (allowedAccessToken && !Array.isArray(allowedAccessToken)) {
    allowedAccessToken = [allowedAccessToken];
  }

  if (allowedTypes && !Array.isArray(allowedTypes)) {
    allowedTypes = [allowedTypes];
  }

  const token = authService.getToken();
  let parsedToken = jwtHelper.decodeToken(
    token as string
  ) as IAccessTokenPayload;

  if (!allowedAccessToken && !parsedToken) {
    return true;
  }

  if (!parsedToken) {
    parsedToken = {} as any;
  }

  if (
    Array.isArray(allowedTypes) &&
    allowedTypes.includes(parsedToken.userType) &&
    Array.isArray(allowedAccessToken) &&
    allowedAccessToken.includes(parsedToken.accessType)
  ) {
    return true;
  }

  if (!(Array.isArray(allowedTypes) && allowedTypes.includes(parsedToken.userType))) {
    authService.clearUser();
  }

  const defaultRoute = accessDefaultRoutesMap[parsedToken.accessType as availableAccessTypes];

  if (defaultRoute && defaultRoute !== state.url) {
    router.navigate([defaultRoute], { queryParamsHandling: "merge" });
  } else {
    router.navigate(["auth/login"]);
  }
  return false;
};
