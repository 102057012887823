import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const managementSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'chatSupport',
    label: 'chatSupport',
    routerLink: '/chat-support',
    permissions: ['VIEW_SUPPORT_CHAT', 'SUPPORT_CHAT_AGENT'],
  },
];
